.PhylosophyContainer {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row; 
}

.TextContainer{
  width: 50%;
  padding: 5%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
}

.Title {
  font-size: 3.5vw;
}
.TextContainer h2{
  font-size: 1.8vw;
  color: #a76400;
}

.Image{
  width: 50%;
  background-image: url('./../../assets/images/philosophy.jpg');
  background-position: center;
  background-size: cover;
}



@media (max-width: 820px) {
  .PhylosophyContainer {
    flex-direction: column;
  }
  .TextContainer{
    width: 100%;
  }
  .Title {
    font-size: 40px;
    text-align: center;
  }
  .TextContainer p{
    font-size: 21px;
  }
  .TextContainer h2{
    font-size: 25px;
  }
  .Image{
    width: 100%;
    height: 100vw;
  }
}