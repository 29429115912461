.CallToActionContainer {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  padding: 100px;
  box-sizing: border-box;

}

.TextContainer{
  width: 50%;
  padding: 5%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
}

.Title {
  font-size: 4.5vw;
}
.CallToActionContainer p{
  font-size: 1.8vw;
  width: 65%;
}




@media (max-width: 820px) {
  .CallToActionContainer {
    padding: 20px;
  }
  .Title {
    font-size: 42px;
  }
  .CallToActionContainer p{
    font-size: 21px;
    width: 100%;
  }
  
}