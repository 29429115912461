.Header {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.Title {
  flex-grow: 2;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: start;
  padding-left: 5vw;
}

.Title a {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #fff;
  font-size: 30px;
}


.dropDownIconContainer {
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 30px;
  padding-right: 5vw;
}

@media (max-width: 820px) {
  .Header {
    height: 100px;
    background-color: #000;
  }
}