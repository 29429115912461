.WorkExperienceContainer {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 100px 0;
  box-sizing: border-box;
}

.Title {
  align-self: center;
  text-align: left;
  max-width: 1000px;
}

.Title span {
  text-transform: uppercase;
  font-size: 2.5vw;

}



.DonwloadContainer{
  width: 100%;
  margin-top: 80px;
  text-align: center;
  font-size: 1.5vw;
  display: flex;
  align-items:center;
  flex-direction: column;
}



@media (max-width: 820px) {
  .WorkExperienceContainer {
    padding: 50px 0;
    justify-content: center;
    align-items: center;
  }
  .DonwloadContainer{
    font-size: 15px;
  }
  .DonwloadContainer h1{
    padding: 0 0.5em;
  }
  .Title {
    width: 80%;
    text-align: center;
  }

  .Title span {
    font-size: 21px;
  }
}