.SkillSetContainer {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  min-height: 600px;
  
}

.TextContainer{
  padding: 5%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
}

.Title {

  font-size: 65px;
}
.TextContainer p{
  font-size: 21px;
  color: #8491a0
}

.SkillsContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
}

.Skill{
  width: 50%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
}

.SkillIcon{
 width: 50px;
 height: 50px;
 margin-bottom: 20px;
}
.SkillTitle{
  color: #fff;
  font-size: 25px;
}
.SkillDescription{
  color: #8491a0;
  font-size: 20px;
}
@media (max-width: 1600px) {
  .Title {
    font-size: 3vw;
  }
}  
@media (max-width: 820px) {
  .SkillSetContainer {
    flex-direction: column;
  }
  .Skill{
    width: 100%;
  }
  .Title{
    font-size: 32px;
  }
  .TextContainer p{
    font-size: 21px;
  }
  .SkillsContainer{
    width: 100%;
  }
  .TextContainer{
    width: 100%;
  }
}