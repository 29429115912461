.DropDownBackground{
  width: 100vw;
  height: 100vh;
  position:absolute;
  z-index:100;
}
.DropDownMenu{
  width: 100%;
  overflow: hidden;
  position:absolute;
  animation: slideDown 500ms ease-in-out;
  animation-fill-mode: forwards;
  display: block;
  top:100px;
  left:0;
  display: flex;
  flex-direction: column;
  z-index:100;
  border-top: 1px solid white;
}

@keyframes slideDown{
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.MenuItem{
  height: 100px;
  width: 100%;
  background-color: #000;
  border-bottom: solid 1px #bababd;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 2em;
  font-size: 20px;
  color: #fff;
  box-sizing: border-box;
}