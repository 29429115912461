.Main{
  width: 100%;
  min-height:100vh;
}

.Mid{
  width: 100%;
}

@media (max-width: 820px) {
  .Mid, .Main{
    height: auto;   
    overflow: visible;
  }
}