.AnchorMenu{
  align-items: center;
  flex-grow: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  font-size: 20px;
  color: #7c7c7c;
  padding-right: 40px;
}

.AnchorItem{
  margin: 0 2em;
}
.AnchorItem:hover{
  color:#a76400;
}

