.FrontPageContainer{
  background-color: #000;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: clip;
}

.DiagonalBk{
  height: 100%;
  background-color: #fff;
  flex: 3 1;
  transform: skew(-45deg);
  margin-left: 250%;
  width: 60vw;
  animation: swipeFromRight 1500ms ease-in-out;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}
.PortraitContainer{
  width: 25vw;
  height: 25vw;
  max-width: 500px;
  max-height: 500px;
  background-image: url('../../assets/images/portrait.jpg');
  overflow: hidden;
  position: absolute;
  left: 10vw;
  top: 25vh;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  filter: grayscale(1);
}

.PortraitContainer img{
  height:100%;
  width: auto;
  filter: saturate(0.5);
}

.TextContainer{
  width: 45vw;
  color: #000;
  text-align: left;
  box-sizing:content-box;
  display: block;
  position: absolute;
  top: 50vh;
  left: 50vw;
}

.Title{
  font-size: 4.5vw;
}
.TitleAlt{
  color: #000;
  font-size: 0.5em;
}
.Subtitle{
  font-weight: 500;
}


@media (max-width: 480px) {
  .PortraitContainer{
    height: 50vw;
    width: 50vw;
    max-width: none;
    max-height: none;

  }
  .TextContainer{
    top: 66vh;

    left: 15vw;
    width: 80vw;
  }
  .Title{
    font-size: 32px;
  }
  .DiagonalBk{
    width: 100vw;
  }
  .Subtitle{
    font-size: 16px;
    font-weight: 300;
  }
}


@keyframes swipeFromRight {
  from {
    margin-left: 250%;
    margin-top: -100%;
  }
  to {
    margin-top: 0;
    margin-left: 50%;
  }
}