.ContactContainer {
  background-color: #fff;
  color: #000;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  font-size: 1.5vw;
}

.Triangle {
  width: 0;
  height: 0;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-top: 40vh solid #000;
}

.Envelope{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
}

.Stamps{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
}

.References, .Form{
  width: 40vw;
  max-width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
}
.Form textarea{
  margin: 1em 30px 0 30px;
  resize: none;
  height: 15vh;
  border: 5px solid #000;
  padding: 1em;
  font-size: 1.5vw;
  font-family: inherit;
}

.Form h1{
  font-size: 2vw;;
  align-self: flex-start;
  margin: 0 30px;
}
.Form a{
  display: flex;
}
.Form a>*{
  flex-grow: 1;
}

@media (max-width: 820px) {
  .Triangle {
    border-top-width: 10vh;
  }
  .Envelope{
    flex-direction: column;
    align-items: center;
    height: 80vh;
  }
  .ContactContainer {
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .References, .Form{
    width: auto;
    height: auto;
  }
  .Form h1{
    font-size: 30px;
  }
}