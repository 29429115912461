.CustomButton{
  margin: 30px;
  padding: 20px;
  font-size: 25px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid;
}
.CustomButton div{
  margin-right: 20px;
}