.ReferencesContainer {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100%;
}

.TextContainer{
  width: 50%;
  padding: 5%;
  height: 100vh;
  text-align: left;
  box-sizing: border-box;
  position:sticky;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-size: 3.5vw;
}

.FeedBackContainer{
  width: 50%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-left: 50%;
  margin-top: -100vh;
}

.FeedBack{
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: left;
  margin-bottom: 50px;
}

.FeedBack > a{
  align-self: center;
}

.FeedBackImage{
  width: 20vw;
  height: 20vw;
  max-width: 300px;
  max-height: 300px;
  min-width: 200px;
  min-height: 200px;
  background-color: lightblue;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  align-self: center;
  margin-bottom: 10px;
  filter: grayscale(1);
}

.FeedBackQuote{
  font-size: 1.5vw;
}
.FeedBackSignature span{
  color: #bababd;
  font-size: 1.5em;
  line-height: 1.8em;
}


@media (max-width: 820px) {
  .ReferencesContainer {
    flex-direction: column;
  }
  .TextContainer{
    width: 100%;
    height: auto;
    position:relative;
  }
  .Title{
    font-size: 32px;
  }
  .TextContainer p{
    font-size: 21px;
  }
  .FeedBackContainer{
    width: 100%;
    margin:0;
  }
  .FeedBackQuote{
    font-size: 30px;
  }
  .Title {
    font-size: 40px;
    text-align: center;
  }
}